import { AppSyncApi } from "./AppSyncApi";

export class CompanyService {
  constructor(token) {
    this.api = new AppSyncApi(token);
  }

  async getAll() {
    const query = `query MyQuery {
        getAllCompanies {
          companyID
          companyName
          ownerID
          address
          description
          industry
          ownerEmail
        }
      }`;

    const response = await this.api.execute(query);
    return response;
  }

  async getAllImagesForCompany(companyID, year, month) {
    const query = `query MyQuery {
        getAllImages(imageDateSettings: { month: ${month}, year: ${year}}, companyID: "${companyID}"){
            imageUrls
            expirationDate
        }
      }`;
    // console.log(query);
    const response = await this.api.execute(query);
    // console.log(response);
    return response.data.data.getAllImages;
  }

  async createDashboard(formState) {
    const query = `mutation MyMutation {
      addCompany(
        companySettings: {
          companyName: "${formState.companyName}"
          address: "${formState.address}"
          description: "${formState.description}"
          industry: "${formState.industry}"
          textPrompt: "Craft a concise, professional, and engaging description for our social media post, highlighting the key attributes of our  ${formState.industry} company. The description should be clear, informative, and no more than 200 characters."
          imagePrompt: "A bright, inviting scene showcasing a prominent ${formState.industry}, surrounded by a pleasant, natural environment."
        }
      ) 
        `;

    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async createNewImage(companyID, companyImagePrompt, year, day, month) {
    const query = `mutation MyMutation {
        createImages(imageDateSettings: { day: ${day}, month: ${month}, year: ${year} }, companyID:"${companyID}",companyImagePrompt:"${companyImagePrompt}")}`;
    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async getCompanyByID(companyID) {
    const query = `query MyQuery {
      getCompanyByID(companyID: "${companyID}") {
        imagePrompt
        textPrompt
        industry
        twitterTokenValid
        linkedinTokenValid
        facebookTokenValid
      }
    }`;
    const response = await this.api.execute(query);
    return response.data.data.getCompanyByID;
  }

  async getCompanyPosts(companyID, postMonth) {
    const query = `query MyQuery {
      getCompanyPosts(companyID:"${companyID}") {
        results {
          companyID
          postID
          postMonth
          postYear
          postDay
          postContent
        }
        pageInfo {
          endCursor
          hasNextPage
          
        }
      }
    }`;
    const response = await this.api.execute(query);

    return response.data.data.getCompanyPosts;
  }

  async createText(companyID, prompt, postDay, postMonth, postYear) {
    const query = `mutation MyMutation {
      createText(companyID: "${companyID}", 
      prompt: "${prompt}", 
      postDay:${postDay}, 
      postMonth: ${postMonth}, 
      postYear:${postYear})
    }
    `;
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async updateCompanyPost(companyID, postID, content, postDay) {
    const query = `mutation MyMutation {
      updateCompanyPost(companyID: "${companyID}", postID: "${postID}", postContent: "${content}", postDay: ${Number(
      postDay
    )}) {
        companyID
        createdAtUnix
        postContent
        postDay
        postID
        postMonth
        postYear
        updatedAtUnix
      }
    }`;
    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async updateCompanyDetails(
    companyID,
    companyName,
    description,
    address,
    industry,
    textPrompt,
    imagePrompt
  ) {
    let fields = "";
    if (description) {
      fields += `description: "${description}" `;
    }
    if (address) {
      fields += `address: "${address}" `;
    }
    if (industry) {
      fields += `industry: "${industry}" `;
    }
    if (textPrompt) {
      fields += `textPrompt: "${textPrompt}" `;
    }
    if (imagePrompt) {
      fields += `imagePrompt: "${imagePrompt}" `;
    }
    const query = `mutation MyMutation {
      updateCompany(
        companySettings: {
          companyID: "${companyID}"
          companyName: "${companyName}"
          ${fields}
        }
      ) {
          companyID
          companyName
          ownerID
          description
          address
          industry
          textPrompt
          imagePrompt
      }
    }`;
    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }

  async putImages(companyID, postDay, postMonth, postYear) {
    const query = `query MyQuery {
      putImages(imageDateSettings: { month: ${postMonth}, year: ${postYear}, day:${postDay} } companyID: "${companyID}"){
          imageUrls
          expirationDate
      }
    }`;

    const response = await this.api.execute(query);
    console.log(response);
    return response;
  }
}
