import React, { useContext } from "react";
import { Link } from "@nextui-org/react";

import UserTimeCalculator from "../../components/homepage/UserTimeCalculator";
import ThreeTiles from "../../components/homepage/ThreeTiles";
import { AuthContext } from "../../App";

const HomePage = (props) => {
  const { isLoggedIn } = useContext(AuthContext);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      {isLoggedIn === false ? (
        <div className="flex flex-col items-center font-lato">
          <h1 className="text-center text-2xl mt-5 sm:text-xl md:text-3xl lg:text-4xl">
            We Craft, You Thrive.
          </h1>
          <h2 className="text-center text-xl mt-2 sm:text-lg md:text-2xl lg:text-3xl">
            Tailored
            <span className="text-custom-red"> Social Media Content</span> for
            your Business.
          </h2>
          <Link
            className="inline-flex items-center px-3 py-1 mt-5 sm:px-5 sm:py-3 sm:mt-4 border border-transparent text-base font-medium rounded-md text-white bg-custom-red hover:bg-indigo-700 "
            variant="outlined"
            href={props.googleAuthEndpoint}
          >
            Register Now
          </Link>
          <UserTimeCalculator />
          <ThreeTiles />
        </div>
      ) : (
        <div className="flex flex-col items-center">loading</div>
      )}
    </div>
  );
};

export default HomePage;
