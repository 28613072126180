import React, { useContext, useEffect, useState } from "react";

// Components
import { AuthContext } from "../../App";
import SubscriptionCards from "../Information/SubscriptionCards";
import CreateDashboardPage from "../UserInput/CreateDashboard";
import CompanySelection from "../../components/dashboard/CompanySelection";
import PromptsAI from "../../components/dashboard/PromptsAI";
import MainCardSection from "../../components/dashboard/MainCardSection";
import LoadingCards from "../../components/reusable/LoadingCards";

// Hooks
import { useCompanyInfo } from "../../hooks/useCompanyInfo";

const DashboardPage = (props) => {
  const {
    selectedCompany,
    setSelectedCompany,
    selectedCompanyDetails,
    setSelectedCompanyDetails,
  } = props;
  // console.log(selectedCompany);

  const { userInfo, services } = useContext(AuthContext);
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const { companyContent, fetchCompanyDetails } = useCompanyInfo(
    selectedCompany,
    services,
    setSelectedCompanyDetails
  );

  // console.log(selectedCompanyDetails);

  const cardKeys = Object.keys(companyContent);
  const currentCardKey = cardKeys[currentCardIndex];
  const currentCardData = companyContent[currentCardKey] || {};

  useEffect(() => {
    console.log("useEffect-mainDas");
    // console.log(userInfo);
    // const params = new URLSearchParams(window.location.search);
    // const oauthToken = params.get("oauth_token");
    // const oauthVerifier = params.get("oauth_verifier");

    // async function fetchAccessToken() {
    //   if (oauthToken && oauthVerifier) {
    //     try {
    //       const twitterAuthUrl =
    //         await services.twitterService.createAccessToken(
    //           selectedCompany.companyID,
    //           oauthToken,
    //           oauthVerifier
    //         );
    //       console.log(twitterAuthUrl);
    //     } catch (error) {
    //       console.error("Error fetching access token:", error);
    //     }
    //   }
    //   // window.history.replaceState(null, null, window.location.pathname);
    // }

    fetchCompanyDetails();

    // fetchAccessToken();
  }, [selectedCompany.companyID, selectedCompany.companyName]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleCompanyChange = (event) => {
    const selected = userInfo?.companies.find(
      (company) => company.companyID === event.target.value
    );
    setSelectedCompany({
      companyName: selected?.companyName,
      companyID: selected?.companyID,
    });
  };

  return (
    // console.log(userInfo, selectedCompany, selectedCompanyDetails),
    <div>
      {userInfo ? (
        <>
          {!userInfo.isSubscriptionExpired ? (
            selectedCompany.companyName &&
            selectedCompany.companyID &&
            selectedCompanyDetails.imagePrompt &&
            selectedCompanyDetails.textPrompt ? (
              <>
                <CompanySelection
                  companies={userInfo.companies}
                  selectedCompany={selectedCompany}
                  handleCompanyChange={handleCompanyChange}
                />
                <PromptsAI
                  selectedCompanyDetails={selectedCompanyDetails}
                  selectedCompany={selectedCompany}
                />
                <MainCardSection
                  companyContent={companyContent}
                  selectedCompany={selectedCompany}
                  selectedCompanyDetails={selectedCompanyDetails}
                  currentCardKey={currentCardKey}
                  currentCardData={currentCardData}
                  cardKeys={cardKeys}
                  currentCardIndex={currentCardIndex}
                  setCurrentCardIndex={setCurrentCardIndex}
                />
              </>
            ) : selectedCompany.companyName === "" &&
              selectedCompany.companyID === "" ? (
              <CreateDashboardPage />
            ) : (
              <LoadingCards />
            )
          ) : (
            <div>
              <SubscriptionCards />
            </div>
          )}
        </>
      ) : (
        <LoadingCards />
      )}
    </div>
  );
};

export default DashboardPage;
