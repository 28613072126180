import { useState } from "react";

const generateInitialImageObject = (
  currentYear,
  currentMonthNumber,
  numberOfDaysInMonth,
  currentMonth
) => {
  return Array.from({ length: numberOfDaysInMonth }, (_, i) => i + 1).reduce(
    (obj, day) => ({
      ...obj,
      [`${currentYear}-${currentMonthNumber}-${day}`]: {
        imageUrl: null,
        year: currentYear,
        month: currentMonthNumber,
        day,
        monthName: currentMonth,
      },
    }),
    {}
  );
};

export const useCompanyInfo = (
  selectedCompany,
  services,
  setSelectedCompanyDetails
) => {
  const [companyContent, setCompanyContent] = useState({});

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonthNumber = currentDate.getMonth() + 1; // Month is 0-indexed
  const currentMonth = currentDate.toLocaleString("default", { month: "long" });
  const numberOfDaysInMonth = new Date(
    currentYear,
    currentMonthNumber,
    0
  ).getDate();

  const fetchCompanyDetails = async () => {
    try {
      if (!selectedCompany.companyID || !selectedCompany.companyName) {
        return;
      }
      const [imagesResponse, companyDetails, companyPosts] = await Promise.all([
        services.companyService.getAllImagesForCompany(
          selectedCompany.companyID,
          currentYear,
          currentMonthNumber
        ),
        services.companyService.getCompanyByID(selectedCompany.companyID),
        services.companyService.getCompanyPosts(
          selectedCompany.companyID,
          currentMonthNumber
        ),
      ]);
      // console.log(companyDetails);
      // console.log(imagesResponse, companyDetails, companyPosts);

      if (imagesResponse === null && companyPosts === null) {
        return;
      }

      if (companyDetails.imagePrompt && companyDetails.textPrompt) {
        setSelectedCompanyDetails({
          imagePrompt: companyDetails.imagePrompt,
          textPrompt: companyDetails.textPrompt,
          industry: companyDetails.industry,
          twitterTokenValid: companyDetails.twitterTokenValid,
        });
      } else {
        setSelectedCompanyDetails({
          imagePrompt: "",
          textPrompt: "",
        });
      }

      let imageObject = generateInitialImageObject(
        currentYear,
        currentMonthNumber,
        numberOfDaysInMonth,
        currentMonth
      );

      if (imagesResponse !== null && companyPosts !== null) {
        let imageArray = imagesResponse.imageUrls;

        for (const imageUrl of imageArray) {
          const url = new URL(imageUrl);
          const pathSegments = url.pathname.split("/");

          const year = pathSegments[3];
          const month = pathSegments[4];
          const day = pathSegments[5];

          imageObject[`${year}-${month}-${day}`] = {
            imageUrl,
            year,
            month,
            day,
            monthName: currentMonth,
          };
        }
        for (const post of companyPosts.results) {
          const year = post.postYear;
          const month = post.postMonth;
          const day = post.postDay;

          // Construct the key
          const key = `${year}-${month}-${day}`;

          // Check if key exists in the imageObject
          if (imageObject[key]) {
            // Add the postContent to the object
            imageObject[key].postContent = post.postContent;
            imageObject[key].postID = post.postID;
          } else {
            // Handle the case where the key doesn't exist, if needed
            console.warn(`Key ${key} not found in imageObject`);
          }
        }
      }
      setCompanyContent(imageObject);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // useEffect(() => {
  //   console.log("useCompanyInfo");
  //   const fetchData = async () => {

  //   console.log(selectedCompany);
  //   if (selectedCompany.companyID) {
  //     fetchData();
  //   }
  // }, [selectedCompany.companyID, selectedCompany.companyName]);

  return { companyContent, fetchCompanyDetails };
};
