import React, { useContext } from "react";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownSection,
  DropdownTrigger,
  Link,
  User,
} from "@nextui-org/react";

import { AuthContext } from "../../App";
import { logOutOperation } from "../../utils/reusableFunctions";

export const UserDropdownMenu = () => {
  const { userInfo } = useContext(AuthContext);

  return (
    <Dropdown placement="bottom" unselectable="on">
      <DropdownTrigger>
        <User
          as="button"
          avatarProps={{
            isBordered: true,
            src: userInfo.profilePicture,
          }}
          className="transition-transform"
          description={userInfo.emailAddress}
          name={userInfo.nickname}
        />
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        <DropdownSection showDivider>
          <DropdownItem key="terms" textValue="Terms and Conditions">
            <Link
              href="/terms-and-conditions"
              color="foreground"
              className="w-full flex"
            >
              Terms and Conditions
            </Link>
          </DropdownItem>
          <DropdownItem key="privacy-policy" textValue="Privacy Policy">
            <Link
              href="/privacy-policy"
              color="foreground"
              className="w-full flex"
            >
              Privacy Policy
            </Link>
          </DropdownItem>
          <DropdownItem key="contact" textValue="Contact Us">
            <Link href="/contact-us" color="foreground" className="w-full flex">
              Contact Us
            </Link>
          </DropdownItem>
          <DropdownItem key="pricing" textValue="Pricing">
            <Link href="/pricing" color="foreground" className="w-full flex">
              Pricing
            </Link>
          </DropdownItem>
        </DropdownSection>
        <DropdownItem
          key="1"
          textValue="Log Out"
          color="danger"
          onClick={logOutOperation}
        >
          Log Out
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};
