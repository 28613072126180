// Libraries
import { createContext, useState, useEffect } from "react";
import { Routes } from "react-router-dom";
import queryString from "query-string";
import jwtDecode from "jwt-decode";
import { NextUIProvider } from "@nextui-org/react";

// Components
import NavbarMenuComponent from "./components/navbar/NavbarMenuComponent";
import {
  getAuthRoutes,
  getAdminRoutes,
  getRegularRoutes,
} from "./routesConfig";

// Hooks
import { useAuth } from "./hooks/useAuth";

// Reusable Functions
import {
  getIdToken,
  isTokenPresent,
  isTokenExpired,
  setToken,
  isUserAdmin,
} from "./utils/reusableFunctions";
import { googleAuthEndpoint } from "./components/constants";

import "./App.css";
import "./style.css";

export const AuthContext = createContext();

function App() {
  const { isLoggedIn, services, userInfo, login, logout, fetchUserInfo } =
    useAuth();

  // console.log(isLoggedIn);

  const [selectedCompany, setSelectedCompany] = useState({
    companyName: "",
    companyID: "",
  });
  const [selectedCompanyDetails, setSelectedCompanyDetails] = useState({});

  useEffect(() => {
    const parsedUrl = queryString.parse(window.location.hash);
    const { access_token, id_token } = parsedUrl;
    if (isTokenPresent()) {
      const decodedIdToken = jwtDecode(getIdToken());
      if (!isTokenExpired(decodedIdToken.exp)) {
        login(getIdToken(), decodedIdToken);
        fetchUserInfo(
          getIdToken(),
          decodedIdToken["cognito:username"],
          setSelectedCompany
        );
      } else {
        logout();
      }
    } else if (access_token && id_token) {
      const decodedIdToken = jwtDecode(id_token);
      setToken(access_token, id_token);
      login(id_token, decodedIdToken);
      fetchUserInfo(
        getIdToken(),
        decodedIdToken["cognito:username"],
        setSelectedCompany
      );
      window.history.replaceState(null, null, window.location.pathname);
    } else {
      logout();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        userInfo: userInfo,
        userCognitoGroups: userInfo.cognitoGroups,
        services: services,
        currentSelectedCompanyName: selectedCompany.companyName,
        currentSelectedCompanyID: selectedCompany.companyID,
        selectedCompanyDetails: selectedCompanyDetails,
      }}
    >
      <NextUIProvider>
        <div className="App">
          <NavbarMenuComponent googleAuthEndpoint={googleAuthEndpoint} />
          <Routes>
            {isLoggedIn && [
              ...getAuthRoutes(
                selectedCompany,
                setSelectedCompany,
                selectedCompanyDetails,
                setSelectedCompanyDetails
              ),
            ]}
            {isLoggedIn && isUserAdmin(userInfo) && [...getAdminRoutes()]}
            {!isLoggedIn && [...getRegularRoutes(googleAuthEndpoint)]}
          </Routes>
        </div>
      </NextUIProvider>
    </AuthContext.Provider>
  );
}

export default App;
