import React from "react";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Button,
  useDisclosure,
  Tooltip,
  Chip,
} from "@nextui-org/react";

import {
  TwitterLoginButton,
  FacebookLoginButton,
  LinkedInLoginButton,
} from "react-social-login-buttons";
import { useContext } from "react";
import { AuthContext } from "../../App";

const loginOptions = [
  {
    serviceName: "Twitter",
    component: TwitterLoginButton,
    tooltip: "",
    socialMediaName: "twitter",
    tokenValidPropertyName: "twitterTokenValid",
  },
  {
    serviceName: "Facebook",
    component: FacebookLoginButton,
    tooltip: "Facebook login coming soon",
    socialMediaName: "facebook",
    tokenValidPropertyName: "facebookTokenValid",
  },
  {
    serviceName: "LinkedIn",
    component: LinkedInLoginButton,
    tooltip: "Linkedin login coming soon",
    socialMediaName: "linkedin",
    tokenValidPropertyName: "linkedinTokenValid",
  },
];

const SocialMediaIcons = () => {
  const {
    currentSelectedCompanyName,
    currentSelectedCompanyID,
    selectedCompanyDetails,
    services,
  } = useContext(AuthContext);

  const [backdrop, setBackdrop] = React.useState("opaque");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const handleOpen = (backdrop) => {
    setBackdrop(backdrop);
    onOpen();
  };

  const onClickSocialIcon = async (event) => {
    if (event.target.textContent === "Login to Twitter") {
      const twitterAuthUrl = await services.twitterService.generateAuthUrl(
        currentSelectedCompanyID
      );
      window.open(twitterAuthUrl, "_blank");
      console.log(twitterAuthUrl);
    }
  };

  return (
    <div className="">
      <>
        <div className="flex flex-wrap gap-3 justify-center">
          <Button key={"blur"} onPress={() => handleOpen("blur")} className="">
            Social Media Sign In
          </Button>
        </div>
        <Modal
          backdrop={backdrop}
          isOpen={isOpen}
          onClose={onClose}
          className="flex"
          size="lg"
        >
          <ModalContent className="flex">
            {(onClose) => (
              <>
                <ModalHeader className="flex flex-col gap-1 ml-2">
                  {"Social Media Login for " + currentSelectedCompanyName}
                </ModalHeader>
                <ModalBody className="flex mb-10 w-full">
                  {loginOptions.map((option, index) => (
                    <div
                      key={index}
                      className="flex w-full justify-between items-center"
                    >
                      <div className="w-8/12">
                        {option.tooltip ? (
                          <Tooltip content={option.tooltip} placement="top">
                            <div className="w-full flex justify-between items-center">
                              <option.component
                                text={`Login to ${option.serviceName}`}
                                onClick={onClickSocialIcon}
                                style={{
                                  opacity: option.tooltip ? 0.5 : 1,
                                  cursor: option.tooltip
                                    ? "not-allowed"
                                    : "pointer",
                                }}
                                disabled={!!option.tooltip}
                              />
                            </div>
                          </Tooltip>
                        ) : (
                          <option.component
                            text={`Login to ${option.serviceName}`}
                            onClick={onClickSocialIcon}
                          />
                        )}
                      </div>
                      <div className="w-4/12 flex justify-end">
                        {selectedCompanyDetails[
                          option.tokenValidPropertyName
                        ] ? (
                          <Chip color="success" size="small">
                            Logged In
                          </Chip>
                        ) : (
                          <Chip color="danger" size="small">
                            Need to login
                          </Chip>
                        )}
                      </div>
                    </div>
                  ))}
                </ModalBody>
              </>
            )}
          </ModalContent>
        </Modal>
      </>
    </div>
  );
};

export default SocialMediaIcons;
