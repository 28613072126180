// Libraries
import { Route } from "react-router-dom";

// Pages
import HomePage from "./pages/Main/HomePage";
import Twitter from "./pages/Main/Twitter";
import DashboardPage from "./pages/Main/MainDashboard";
import ListOfCompaniesPage from "./pages/Admin/ListOfCompaniesPage";
import AdminOnboardCompaniesPage from "./pages/Admin/OnboardCompaniesPage";
import ContactUs from "./pages/UserInput/ContactUs";
import PrivacyPolicy from "./pages/Information/PrivacyPolicy";
import TermsAndConditions from "./pages/Information/TermsAndConditions";
import NotFound from "./pages/Information/NotFound";
import SubscriptionCards from "./pages/Information/SubscriptionCards";

export const getAuthRoutes = (
  selectedCompany,
  setSelectedCompany,
  selectedCompanyDetails,
  setSelectedCompanyDetails
) => [
  <Route
    key="dashboard"
    path="/"
    element={
      <DashboardPage
        {...{
          selectedCompany,
          setSelectedCompany,
          selectedCompanyDetails,
          setSelectedCompanyDetails,
        }}
      />
    }
  />,
  <Route key="twitter" path="/twitter" element={<Twitter />} />,
  <Route key="pricing" path="/pricing" element={<SubscriptionCards />} />,
  <Route key="contact-us" path="/contact-us" element={<ContactUs />} />,
  <Route
    key="privacy-policy"
    path="/privacy-policy"
    element={<PrivacyPolicy />}
  />,
  <Route
    key="terms-conditions"
    path="/terms-and-conditions"
    element={<TermsAndConditions />}
  />,
  <Route key="not-found" path="*" element={<NotFound />} />,
];

export const getAdminRoutes = () => [
  <Route
    key="list-of-companies"
    path="/list-of-companies"
    element={<ListOfCompaniesPage />}
  />,
  <Route
    key="admin-onboard"
    path="/admin-onboard-companies"
    element={<AdminOnboardCompaniesPage />}
  />,
];

export const getRegularRoutes = (googleAuthEndpoint) => [
  <Route
    key="home"
    path="/"
    element={<HomePage googleAuthEndpoint={googleAuthEndpoint} />}
  />,
  <Route key="pricing" path="/pricing" element={<SubscriptionCards />} />,
  <Route key="contact-us" path="/contact-us" element={<ContactUs />} />,
  <Route
    key="privacy-policy"
    path="/privacy-policy"
    element={<PrivacyPolicy />}
  />,
  <Route
    key="terms-conditions"
    path="/terms-and-conditions"
    element={<TermsAndConditions />}
  />,
  <Route key="not-found" path="*" element={<NotFound />} />,
];
