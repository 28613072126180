import React from "react";
import { Card, CardBody } from "@nextui-org/react";

const titleData = [
  {
    title: "Photos",
    description:
      "We create engaging photos and compelling narratives for your business.",
  },
  {
    title: "Videos",
    description:
      "We generate concise, impactful videos to captivate your audience.",
  },
  {
    title: "AI Assistance",
    description:
      "Our AI crafts personalized responses for effective customer interactions.",
  },
];

const ThreeTiles = () => (
  <div className="flex flex-col justify-between items-center h-full gap-x-4 gap-y-4 md:flex-row  md:gap-y-0 m-4 md:m-10 w-full md:w-3/4">
    {titleData.map((tile, index) => (
      <Card
        shadow="md"
        key={index}
        className={`bg-red-200 border border-red-400 rounded-xl shadow-2xl transform hover:scale-105 transition-transform duration-500 `}
      >
        <CardBody className="flex flex-col justify-center items-center h-full p-4 md:p-6 text-center">
          <h3 className="mb-2 md:mb-4 font-semibold text-xl md:text-2xl">
            {tile.title}
          </h3>
          <p className="text-base md:text-lg">{tile.description}</p>
        </CardBody>
      </Card>
    ))}
  </div>
);

export default ThreeTiles;
