import { useState } from "react";
import { UserService } from "../utils/UserService";
import { CompanyService } from "../utils/CompanyService";
import { StripeService } from "../utils/StripeService";
import { TwitterService } from "../utils/TwitterService";
import { removeTokens, isTokenExpired } from "../utils/reusableFunctions";
export const useAuth = () => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [services, setServices] = useState({});
  const [userInfo, setUserInfo] = useState({});

  const login = (idToken, decodedIdToken) => {
    setUserInfo({
      emailAddress: decodedIdToken.email || null,
      emailVerified: decodedIdToken.email_verified || null,
      nickname: decodedIdToken.nickname || null,
      profilePicture: decodedIdToken.picture || null,
      givenName: decodedIdToken.given_name || null,
      cognitoGroups: decodedIdToken["cognito:groups"] || null,
      idToken: idToken,
      cognitoUsername: decodedIdToken["cognito:username"] || null,
    });

    setLoggedIn(true);

    setServices({
      companyService: new CompanyService(idToken),
      userService: new UserService(idToken),
      stripeService: new StripeService(idToken),
      twitterService: new TwitterService(idToken),
    });
  };

  const logout = () => {
    setLoggedIn(false);
    removeTokens();
    setServices({});
  };

  const fetchUserInfo = async (
    idTokenCredential,
    cognitoUsername,
    setSelectedCompany
  ) => {
    const userService = new UserService(idTokenCredential);
    const response = await userService.getUserByID(cognitoUsername);
    const companiesData = response.companies || [];
    // console.log(companiesData);
    const subscriptionEndUnit = response.subscriptionEndUnix;
    let isSubscriptionExpired =
      subscriptionEndUnit === null || subscriptionEndUnit === undefined
        ? true
        : isTokenExpired(subscriptionEndUnit);

    setUserInfo((prevUserInfo) => ({
      ...prevUserInfo,
      companies: companiesData,
      isSubscriptionExpired: isSubscriptionExpired,
      currentTokens: response.currentTokens,
      tokenCount: response.tokenCount,
      planName: response.planName,
      subscriptionEndUnix: response.subscriptionEndUnix,
    }));
    setSelectedCompany({
      companyName: companiesData[0]?.companyName || "",
      companyID: companiesData[0]?.companyID || "",
    });
  };

  return { isLoggedIn, services, userInfo, login, logout, fetchUserInfo };
};
