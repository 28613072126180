import { AppSyncApi } from "./AppSyncApi";

export class TwitterService {
  constructor(token) {
    this.api = new AppSyncApi(token);
  }

  async generateAuthUrl(companyID) {
    console.log(companyID);
    const query = `query MyQuery {
      twitterAuthOperations(companyID: "${companyID}", twitterVerifier:"" ,endpoint:"request_token")
  }`;
    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    const jsonResponse = JSON.parse(response.data.data.twitterAuthOperations);
    console.log(jsonResponse.body.response);
    return jsonResponse.body.response;
  }

  async createAccessToken(companyID, twitterVerifier, twitterRequestToken) {
    const query = `query MyQuery {
      twitterAuthOperations(companyID: "${companyID}", twitterVerifier:"${twitterVerifier}", endpoint:"access_token", twitterRequestToken:"${twitterRequestToken}")
  }`;
    console.log(query);
    const response = await this.api.execute(query);
    console.log(response);
    const jsonResponse = JSON.parse(response.data.data.twitterAuthOperations);
    console.log(JSON.parse(jsonResponse.body));
    return jsonResponse.body.response;
  }

  async test() {
    console.log("hello");
  }
}
