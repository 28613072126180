import { Select, SelectItem } from "@nextui-org/react";

const CompanySelection = (props) => {
  const { companies, selectedCompany, handleCompanyChange } = props;
  return (
    <Select
      label="Company"
      className="max-w-xs w-48 mt-5"
      onChange={handleCompanyChange}
      disabledKeys={[selectedCompany?.companyID]}
      defaultSelectedKeys={[selectedCompany?.companyID]}
    >
      {companies.map((item, index) => (
        <SelectItem key={item.companyID} value={item.companyID}>
          {item.companyName}
        </SelectItem>
      ))}
    </Select>
  );
};

export default CompanySelection;
