import environmentVariables from "../envVariables";

export const monthNamesLookup = {
  January: 0,
  February: 1,
  March: 2,
  April: 3,
  May: 4,
  June: 5,
  July: 6,
  August: 7,
  September: 8,
  October: 9,
  November: 10,
  December: 11,
};

export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export function getDay(month, date, year) {
  const fullDate = new Date(year, monthNamesLookup[month], date);
  const dayOfWeekIndex = fullDate.getDay();

  return daysOfWeek[dayOfWeekIndex];
}

export const googleAuthEndpoint = `${environmentVariables["COGNITO_AUTH_ENDPOINT"]}/oauth2/authorize?identity_provider=Google&client_id=${environmentVariables["COGNITO_CLIENT_ID"]}&response_type=TOKEN&scope=email+openid+profile&redirect_uri=${environmentVariables["CLOUDFRONT_DOMAIN_URL"]}`;

export const getImagePrompts = (selectedCompanyDetails) => {
  const imagePromptsV2 = [
    {
      id: 1,
      tags: "bright, inviting, pleasant",
      prompt: `A bright, inviting scene showcasing a prominent ${selectedCompanyDetails.industry}, surrounded by a pleasant, natural environment.`,
    },
    {
      id: 2,
      tags: "well-lit, close-up, details, clean",
      prompt: `A well-lit, close-up shot highlighting the details of a ${selectedCompanyDetails.industry} in a clean, neutral setting.`,
    },
    {
      id: 3,
      tags: "vibrant, rich, appealing",

      prompt: `A vibrant representation of a ${selectedCompanyDetails.industry}, with rich, appealing colors and a backdrop that complements it.`,
    },
  ];
  return imagePromptsV2;
};

export const getTextPrompts = (selectedCompanyDetails) => {
  const textPromptsV2 = [
    {
      id: 1,
      tags: "concise, professional, engaging",
      prompt: `Craft a concise, professional, and engaging description for our social media post, highlighting the key attributes of our  ${selectedCompanyDetails.industry} company. The description should be clear, informative, and no more than 200 characters.`,
    },
    {
      id: 2,
      tags: "humorous, catchy, caption",
      prompt: `Create a humorous and catchy caption that cleverly incorporates elements related to the ${selectedCompanyDetails.industry}. Make it light-hearted and memorable, all within 200 characters.`,
    },
    {
      id: 3,
      tags: "inspiring, uplifting, connecting",
      prompt: `Compose an inspiring and uplifting message for our social media, connecting our values with the essence of the ${selectedCompanyDetails.industry}. Aim to motivate and resonate with our audience, keeping it under 200 characters.`,
    },
  ];
  return textPromptsV2;
};
