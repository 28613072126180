import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
  Link,
  NavbarItem,
  Tooltip,
  Button,
  NavbarMenuToggle,
  NavbarMenu,
  NavbarMenuItem,
} from "@nextui-org/react";
import { UserDropdownMenu } from "../navbar/UserDropdownMenu";
import SocialMediaIcons from "../dashboard/SocialMediaIcons";
import { logOutOperation } from "../../utils/reusableFunctions";

import { AuthContext } from "../../App";

const determineDropdownElements = (
  userInfo,
  isLoggedIn,
  googleAuthEndpoint
) => {
  // console.log(userInfo);

  if (!isLoggedIn) {
    return [
      {
        id: 1,
        title: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        id: 2,
        title: "Terms & Conditions",
        href: "/terms-and-conditions",
      },
      {
        id: 3,
        title: "Pricing",
        href: "/pricing",
      },
      {
        id: 4,
        title: "Contact Us",
        href: "/contact-us",
      },
      {
        id: 5,
        title: "Log In",
        href: googleAuthEndpoint,
      },
    ];
  } else if (userInfo?.cognitoGroups?.includes("Admins")) {
    return [
      {
        id: 1,
        title: "Dashboard",
        href: "/",
      },
      {
        id: 2,
        title: "Companies",
        href: "/list-of-companies",
      },
      {
        id: 3,
        title: "Onboard Company",
        href: "/admin-onboard-companies",
      },
      {
        id: 4,
        title: "Metrics",
        href: "/metrics",
      },
      {
        id: 5,
        title: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        id: 6,
        title: "Terms & Conditions",
        href: "/terms-and-conditions",
      },
      {
        id: 7,
        title: "Pricing",
        href: "/pricing",
      },
      {
        id: 8,
        title: "Contact Us",
        href: "/contact-us",
      },
      {
        id: 9,
        title: "Log Out",
        href: "/",
        onClick: () => logOutOperation(),
      },
    ];
  } else if (userInfo.isSubscriptionExpired === false) {
    return [
      {
        id: 1,
        title: "Dashboard",
        href: "/",
      },
      {
        id: 2,
        title: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        id: 3,
        title: "Terms & Conditions",
        href: "/terms-and-conditions",
      },
      {
        id: 4,
        title: "Pricing",
        href: "/pricing",
      },
      {
        id: 5,
        title: "Contact Us",
        href: "/contact-us",
      },
      {
        id: 6,
        title: "Log Out",
        href: "/",
        onClick: () => logOutOperation(),
      },
    ];
  } else {
    return [
      {
        id: 1,
        title: "Privacy Policy",
        href: "/privacy-policy",
      },
      {
        id: 2,
        title: "Terms & Conditions",
        href: "/terms-and-conditions",
      },
      {
        id: 3,
        title: "Pricing",
        href: "/pricing",
      },
      {
        id: 4,
        title: "Contact Us",
        href: "/contact-us",
      },
      {
        id: 5,
        title: "Log Out",
        href: "/",
        onClick: () => logOutOperation(),
      },
    ];
  }
};

const AdminMenu = [
  {
    title: "Companies",
    href: "/list-of-companies",
  },
  {
    title: "Onboard Company",
    href: "/admin-onboard-companies",
  },
  {
    title: "Metrics",
    href: "/metrics",
  },
];

const BasicUserMenu = [
  {
    title: "Dashboard",
    href: "/",
  },
];

const BasicMenu = [
  {
    title: "Privacy Policy",
    href: "/privacy-policy",
  },
  {
    title: "Terms & Conditions",
    href: "/terms-and-conditions",
  },
];

export const AdminDropdown = () => (
  <NavbarItem>
    <Dropdown placement="bottom" unselectable="on">
      <DropdownTrigger>
        <Link isBlock color={"foreground"} className="p-2 px-3">
          Admin
        </Link>
      </DropdownTrigger>
      <DropdownMenu aria-label="Profile Actions" variant="flat">
        {AdminMenu.map((item, index) => {
          return (
            <DropdownItem key={index}>
              <Link href={item.href} color={"foreground"}>
                {item.title}
              </Link>
            </DropdownItem>
          );
        })}
      </DropdownMenu>
    </Dropdown>
  </NavbarItem>
);

export const BasicUserNavElements = () => {
  const location = useLocation();

  return (
    <>
      {BasicUserMenu.map((item, index) => {
        const active = location.pathname === item.href;
        return (
          <NavbarItem key={index} isActive={active}>
            <Link
              isBlock
              href={item.href}
              color={active ? "primary" : "foreground"}
              className="p-2 px-3"
            >
              {item.title}
            </Link>
          </NavbarItem>
        );
      })}
    </>
  );
};

export const NotLoggedInMenu = (props) => {
  const location = useLocation();
  return (
    <NavbarItem>
      <Link
        isBlock
        href={"/pricing"}
        color={location.pathname === "/pricing" ? "primary" : "foreground"}
        className="p-2 px-3"
      >
        Pricing
      </Link>
      <Dropdown placement="bottom" unselectable="on">
        <DropdownTrigger>
          <Link isBlock color={"foreground"} className="p-2 px-3">
            Company
          </Link>
        </DropdownTrigger>
        <DropdownMenu aria-label="Navigation Actions" variant="flat">
          {BasicMenu.map((item, index) => {
            const active = location.pathname === item.href;
            return (
              <DropdownItem key={index}>
                <Link
                  href={item.href}
                  color={active ? "primary" : "foreground"}
                  className="block py-2 px-4"
                >
                  {item.title}
                </Link>
              </DropdownItem>
            );
          })}
        </DropdownMenu>
      </Dropdown>
      <Link isBlock href={props.googleAuthEndpoint} className="p-2 px-3">
        Login
      </Link>
    </NavbarItem>
  );
};

export const NonPaidUserLoggedInMenu = (props) => {
  return <UserDropdownMenu />;
};

export const PaidUserLoggedInMenu = (props) => {
  const { userInfo } = useContext(AuthContext);
  // console.log(userInfo);
  return (
    <>
      <SocialMediaIcons />
      <Tooltip
        content={`${userInfo.currentTokens}/${userInfo.tokenCount} Remaining Tokens`}
      >
        <Button>Tokens</Button>
      </Tooltip>
      <UserDropdownMenu />
    </>
  );
};

export const HamburgerMenuDropdown = (props) => {
  const { userInfo, isLoggedIn } = useContext(AuthContext);
  const dropdownElements = determineDropdownElements(
    userInfo,
    isLoggedIn,
    props.googleAuthEndpoint
  );

  return (
    <>
      <NavbarMenuToggle
        aria-label={props.isMenuOpen ? "Close menu" : "Open menu"}
      />
      <NavbarMenu>
        {dropdownElements.map((item, index) => (
          <NavbarMenuItem key={index}>
            <Link
              className="text-center hover:border hover:border-gray-500 text-red-500"
              href={item.href}
              size="lg"
              onClick={item.onClick}
            >
              {item.title}
            </Link>
          </NavbarMenuItem>
        ))}
      </NavbarMenu>
    </>
  );
};
