import {
  Button,
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import React, { useState } from "react";
import { useContext } from "react";
import { AuthContext } from "../../App";
import { getImagePrompts, getTextPrompts } from "../../components/constants";

const PromptsAI = (props) => {
  const { selectedCompanyDetails, selectedCompany } = props;

  const [selectedPrompt, setSelectedPrompt] = useState({
    imagePrompt: selectedCompanyDetails.imagePrompt,
    textPrompt: selectedCompanyDetails.textPrompt,
  });
  const { services } = useContext(AuthContext);

  // console.log(selectedCompanyDetails, selectedCompany);
  const imagePrompts = getImagePrompts(selectedCompanyDetails.industry);
  const textPrompts = getTextPrompts(selectedCompanyDetails.industry);

  const handleImagePromptChange = async (item) => {
    setSelectedPrompt((prev) => {
      const newPrompt = {
        ...prev,
        imagePrompt: item,
      };
      handlePromptSubmit(newPrompt); // This will use the updated state
      return newPrompt;
    });
  };

  const handleTextPromptChange = async (item) => {
    setSelectedPrompt((prev) => {
      const newPrompt = {
        ...prev,
        textPrompt: item,
      };
      handlePromptSubmit(newPrompt); // This will use the updated state
      return newPrompt;
    });
  };

  const handlePromptSubmit = async (newPrompt) => {
    console.log("handlePromptSubmit");
    // console.log(newPrompt.textPrompt, newPrompt.imagePrompt);
    const response = services.companyService.updateCompanyDetails(
      selectedCompany.companyID,
      selectedCompany.companyName,
      null,
      null,
      null,
      newPrompt.textPrompt,
      newPrompt.imagePrompt
    );
    console.log(response);
  };

  return (
    <>
      {selectedCompanyDetails.imagePrompt &&
        selectedCompanyDetails.textPrompt &&
        selectedCompanyDetails.industry && (
          <>
            <div className="flex content-center px-10 mt-2 w-10/12 mx-auto center">
              <Dropdown backdrop="blur">
                <DropdownTrigger>
                  <Button variant="bordered">Images Prompt</Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="faded"
                  aria-label="Static Actions"
                  disabledKeys={[selectedPrompt.imagePrompt]}
                  className="" // Additional Tailwind classes
                >
                  {imagePrompts.map((prompt) => (
                    <DropdownItem
                      className="flex "
                      key={prompt.id}
                      value={prompt.tags}
                      onClick={() => {
                        handleImagePromptChange(prompt.tags);
                      }}
                    >
                      {prompt.tags}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>

              <Dropdown backdrop="blur">
                <DropdownTrigger>
                  <Button variant="bordered">Text Prompt</Button>
                </DropdownTrigger>
                <DropdownMenu
                  variant="faded"
                  aria-label="Static Actions"
                  disabledKeys={[selectedPrompt.textPrompt]}
                  className="" // Adjusted Tailwind classes for width
                >
                  {textPrompts.map((prompt) => (
                    <DropdownItem
                      key={prompt.id}
                      value={prompt.tags}
                      onClick={() => handleTextPromptChange(prompt.tags)}
                    >
                      {prompt.tags}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </Dropdown>
            </div>
          </>
        )}
    </>
  );
};

export default PromptsAI;
