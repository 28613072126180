import React from "react";
import { Button } from "@nextui-org/react";

import CardTile from "./CardTile";

const MainCardSection = (props) => {
  const {
    companyContent,
    selectedCompany,
    selectedCompanyDetails,
    currentCardKey,
    currentCardData,
    cardKeys,
    currentCardIndex,
    setCurrentCardIndex,
  } = props;

  const handlePreviousCard = () => {
    if (currentCardIndex > 0) {
      setCurrentCardIndex(currentCardIndex - 1);
    }
    // console.log(currentCardIndex);
  };

  const handleNextCard = () => {
    if (currentCardIndex < Object.keys(companyContent).length - 1) {
      setCurrentCardIndex(currentCardIndex + 1);
    }
    // console.log(currentCardIndex);
  };

  return (
    <>
      <div className="hidden xl:flex mt-5">
        <div className="flex-row flex-wrap center items-center">
          {Object.keys(companyContent).map((dateKey, index) => {
            const companyData = companyContent[dateKey];
            return (
              <CardTile
                key={index + 1}
                singleCard={false}
                currentDay={companyData.day}
                currentMonth={companyData.monthName}
                currentMonthNumber={companyData.month}
                currentYear={companyData.year}
                imageUrl={companyData.imageUrl}
                companyID={selectedCompany.companyID}
                companyImagePrompt={selectedCompanyDetails.imagePrompt}
                companyTextPrompt={selectedCompanyDetails.textPrompt}
                companyPosts={companyData.postContent}
                companyPostID={companyData.postID}
              />
            );
          })}
        </div>
      </div>
      <div className="xl:hidden w-full mt-10">
        <div className="flex w-full justify-center items-center">
          {/* Increase the dimensions and font size for the CardTile */}
          <CardTile
            singleCard={true}
            key={currentCardKey}
            currentDay={currentCardData.day}
            currentMonth={currentCardData.monthName}
            currentMonthNumber={currentCardData.month}
            currentYear={currentCardData.year}
            imageUrl={currentCardData.imageUrl}
            companyID={selectedCompany.companyID}
            companyImagePrompt={selectedCompanyDetails.imagePrompt}
            companyTextPrompt={selectedCompanyDetails.textPrompt}
            companyPosts={currentCardData.postContent}
            companyPostID={currentCardData.postID}
          />
        </div>
        <Button
          className="absolute left-0 ml-4 mt-5 "
          color="danger"
          disabled={currentCardIndex === 0}
          onClick={handlePreviousCard}
          auto
        >
          {"<"}
        </Button>

        <Button
          className="absolute right-0 mr-4 mt-5"
          color="danger"
          disabled={currentCardIndex === cardKeys.length - 1}
          onClick={handleNextCard}
          auto
        >
          {">"}
        </Button>
      </div>
    </>
  );
};

export default MainCardSection;
