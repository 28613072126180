export const getIdToken = () => localStorage.getItem("id_token");
export const getAccessToken = () => localStorage.getItem("access_token");
export const isTokenPresent = () => getAccessToken() && getIdToken();

export const isTokenExpired = (exp) => {
  const currentUnixTimestamp = Math.floor(Date.now() / 1000);
  return currentUnixTimestamp >= exp;
};

export const setToken = (access_token, id_token) => {
  localStorage.setItem("access_token", access_token);
  localStorage.setItem("id_token", id_token);
};

export const removeAccessToken = () => localStorage.removeItem("access_token");
export const removeIdToken = () => localStorage.removeItem("id_token");

export const removeTokens = () => {
  removeAccessToken();
  removeIdToken();
};

export const isUserAdmin = (userInfo) => {
  return userInfo.cognitoGroups && userInfo.cognitoGroups.includes("Admins");
};

export const logOutOperation = (event) => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("id_token");
  window.location.href = "/";
};

// export function gqlInputFromArrayOfObjects(arr) {
//   return arr
//     .map(
//       (obj) =>
//         `{${Object.entries(obj)
//           .map(([k, v]) => `${k}: "${v}"`)
//           .join(", ")}}`
//     )
//     .join(", ");
// }
