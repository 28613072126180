// Libraries
import React, { useContext } from "react";
import { Navbar, NavbarContent } from "@nextui-org/react";

// Reusable Components
import { isUserAdmin } from "../../utils/reusableFunctions";

import {
  AdminDropdown,
  BasicUserNavElements,
  NotLoggedInMenu,
  PaidUserLoggedInMenu,
  HamburgerMenuDropdown,
  NonPaidUserLoggedInMenu,
} from "../reusable/NavbarElements";

import logo from "../../images/MediaPilotLogo.png";
import { AuthContext } from "../../App";

const NavbarMenuComponent = (props) => {
  const { isLoggedIn, userInfo } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);

  return (
    <Navbar
      isBordered
      isBlurred
      maxWidth="2xl"
      className="select-none border-b"
      isMenuOpen={isMenuOpen}
      onMenuOpenChange={setIsMenuOpen}
    >
      <a href="/">
        <img
          className="items-center h-12 object-scale-down"
          src={logo}
          alt="Logo"
        />
      </a>

      <NavbarContent justify="start" className="hidden md:flex ">
        {isLoggedIn && <BasicUserNavElements />}
        {isLoggedIn && isUserAdmin(userInfo) ? <AdminDropdown /> : null}
      </NavbarContent>

      <NavbarContent justify="end" className="hidden md:flex">
        {!isLoggedIn ? (
          <NotLoggedInMenu googleAuthEndpoint={props.googleAuthEndpoint} />
        ) : isLoggedIn && userInfo.isSubscriptionExpired ? (
          <NonPaidUserLoggedInMenu
            googleAuthEndpoint={props.googleAuthEndpoint}
          />
        ) : isLoggedIn && !userInfo.isSubscriptionExpired ? (
          <PaidUserLoggedInMenu googleAuthEndpoint={props.googleAuthEndpoint} />
        ) : null}
      </NavbarContent>

      <NavbarContent justify="end" className="flex md:hidden">
        <HamburgerMenuDropdown
          isMenuOpen={isMenuOpen}
          googleAuthEndpoint={props.googleAuthEndpoint}
        />
      </NavbarContent>
    </Navbar>
  );
};

export default NavbarMenuComponent;
