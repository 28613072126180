import React, { useEffect } from "react";
import Button from "@mui/material/Button";

const Twitter = (props) => {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    console.log(params);
    console.log(window.location.search);
    const oauthToken = params.get("oauth_token");
    console.log(`oauth_token: ${oauthToken}`);
  });
  return (
    <div>
      <Button variant="outlined">Twitter Page</Button>
    </div>
  );
};

export default Twitter;
