import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Card, CardBody, CardHeader, Chip, Button } from "@nextui-org/react";
import { LucideBadgeCheck } from "lucide-react";

import { AuthContext } from "../../App";

const list = [
  {
    title: "Basic Plan",
    price: 17.0,
    features: [
      "AI Image Generation",
      "AI Description Generation",
      "Automatic Social Media Posting",
    ],
    planName: "basic",
    comingSoon: false,
  },
  {
    title: "Standard Plan",
    price: 28.0,
    features: ["Everything from Basic Plan", "Automatic Social Media Posting"],
    comingSoon: false,
  },
  {
    title: "Premium Plan",
    price: 53.0,
    features: ["Everything from Standard Plan", "AI Reviews Generation"],
    comingSoon: true,
  },
];

const SubscriptionCards = () => {
  const { isLoggedIn, services, userInfo } = useContext(AuthContext);
  // const [counts, setCounts] = useState(Array(list.length).fill(1));
  const location = useLocation();

  // const decrementCount = (index) => {
  //   if (counts[index] > 1) {
  //     const newCounts = [...counts];
  //     newCounts[index] -= 1;
  //     setCounts(newCounts);
  //   }
  // };

  // const incrementCount = (index) => {
  //   const newCounts = [...counts];
  //   newCounts[index] += 1;
  //   setCounts(newCounts);
  // };

  const handleBuyNowClick = async (planName) => {
    const linkToPayment = await services.stripeService.getStripeCheckoutLink(
      userInfo.emailAddress,
      planName,
      1
    );

    console.log(linkToPayment.data.data.getStripeCheckoutLink);
    window.location.href = linkToPayment.data.data.getStripeCheckoutLink;

    // Add your purchase logic here.
    // It can be redirecting to a checkout page, calling an API to initialize a transaction, etc.
  };

  return (
    <div className="flex flex-col justify-center items-center h-full mt-10 mb-10 w-full">
      <h2 className="text-2xl sm:text-3xl font-bold mb-6 text-center">
        Ready to take flight?
      </h2>
      <div className="flex flex-col lg:flex-row gap-4 justify-center items-center w-full">
        {list.map((item, index) => (
          <Card
            shadow="sm"
            key={index}
            isPressable={false}
            onPress={() => console.log("item pressed")}
            className={`${index === 1 ? "w-96 h-96" : "w-96 h-80"} ${
              item.comingSoon ? "opacity-60" : ""
            } hover:scale-105 justify-between bg-red-200 border border-red-400 `}
          >
            <CardHeader className="flex-row justify-start">
              <p className="flex text-lg font-bold flex-auto">{item.title}</p>
              <Chip
                color="secondary"
                isDisabled={item.comingSoon}
                className="justify-end"
              >
                {item.comingSoon ? `Coming Soon` : `Available`}
              </Chip>
            </CardHeader>
            <CardBody className="flex flex-col">
              <div className="flex flex-row items-baseline">
                <div className="text-3xl font-bold">{`$${item.price.toFixed(
                  2
                )}`}</div>
                <div className="font-light ">/ month</div>
                {/* <div className="">
                  <span className="bg-gray-200 text-gray-600 text-xs px-2 py-1 rounded">
                    Per Company
                  </span>
                </div> */}
              </div>

              <ul className=" flex-col pt-2 items-start">
                {item.features.map((feature, index) => (
                  <li
                    key={index}
                    className="flex flex-row gap-1.5 items-center"
                  >
                    <LucideBadgeCheck className="opacity-60 w-5 h-5" />
                    {feature}
                  </li>
                ))}
              </ul>
              {isLoggedIn && location.pathname === "/" && !item.comingSoon ? (
                <>
                  {/* <div className="mt-2">
                    <div className="flex center">
                      <Button
                        onClick={() => decrementCount(index)}
                        variant="decrement"
                      >
                        -
                      </Button>
                      <p style={{ margin: "0 10px" }}>{counts[index]}</p>
                      <Button
                        onClick={() => incrementCount(index)}
                        variant="increment"
                      >
                        +
                      </Button>
                    </div>
                  </div> */}
                  <div className="flex center">
                    <Button
                      className="bg-transparent border border-red-400"
                      onClick={() => handleBuyNowClick(item.planName)}
                    >
                      Buy Now
                    </Button>
                  </div>
                </>
              ) : null}
            </CardBody>
          </Card>
        ))}
      </div>
    </div>
  );
};
export default SubscriptionCards;
