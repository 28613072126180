import React from "react";
import { Skeleton, Card } from "@nextui-org/react";

const LoadingCards = () => {
  const cards = (numOfCards) => {
    return Array.from({ length: numOfCards }, (_, i) => (
      <Card
        key={i}
        className={`flex space-y-5 p-4 m-2 radius-2xl ${
          numOfCards === 1 ? "w-5/6" : "w-1/6"
        }`}
        radius="2xl"
      >
        <Skeleton className="rounded-lg">
          <div className="h-24 rounded-lg bg-default-300"></div>
        </Skeleton>
        <div className="space-y-3">
          <Skeleton className="w-3/5 rounded-lg">
            <div className="h-3 w-3/5 rounded-lg bg-default-200"></div>
          </Skeleton>
          <Skeleton className="w-4/5 rounded-lg">
            <div className="h-3 w-4/5 rounded-lg bg-default-200"></div>
          </Skeleton>
          <Skeleton className="w-2/5 rounded-lg">
            <div className="h-3 w-2/5 rounded-lg bg-default-300"></div>
          </Skeleton>
        </div>
      </Card>
    ));
  };
  return (
    <>
      <div className="hidden xl:flex flex-row flex-wrap pt-10 pl-16 justify-center items-center">
        {cards(30)}
      </div>
      <div className="xl:hidden center">{cards(1)}</div>
    </>
  );
};

export default LoadingCards;
